/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import "react-calendar/dist/Calendar.css";

///Images

//Import
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../../context/ThemeContext";
import { fetchemployeeData } from "../../../store/reducers/employee";

const Home = () => {
  const dispatch = useDispatch();
  const { employeeCount, loading } = useSelector((state) => state.employee);
  useEffect(() => {
    dispatch(fetchemployeeData());
  }, []);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "dark", label: "dark" });
  }, []);
  return (
    <>
      {loading ? <div className="loader"></div> : null}

      <div className="row">
        <div className="col-xl-4 col-sm-4">
          <div className="card gradient-1 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{employeeCount}</h2>
                <span className="fs-18">Users</span>
              </div>
              <img src="/img/group.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        {/* <div className="col-xl-4 col-sm-4">
          <div className="card gradient-2 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{moduleCount}</h2>
                <span className="fs-18">Module</span>
              </div>
              <img src="/img/employee.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-4">
          <div className="card gradient-3 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{0}</h2>
                <span className="fs-18">Sub-Module</span>
              </div>
              <img src="/img/driver.png" width={"45%"} alt="" />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Home;
