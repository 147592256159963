import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import NavHader from "./NavHader";
import SideBar from "./SideBar";

const JobieNav = ({
  title,
  onClick: ClickToAddEvent,

  status,
}) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const navigate = useNavigate();
  useEffect(() => {
    if (status === "unpaid") {
      navigate("/Packages");
    }
  }, [navigate, status]);
  return (
    <Fragment>
      <NavHader status={status} />
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      {status === "paid" && <SideBar />}
    </Fragment>
  );
};

export default JobieNav;
